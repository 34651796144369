import styled from "styled-components";

export const ServicesContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #0a0803;
    min-height: 100%;
    flex: 1;
    position: relative;

    @media screen and (max-width: 768px){
        height: 1100px;    
        margin-top: 200px;
    };

    @media screen and (max-width: 480px){
        height: 1300px;   
        margin-top: 300px;
    };
`;

export const ServicesWrapper = styled.div`
    max-width: 1000px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    grid-gap: 20px;
    padding: 0 50px;
    margin-top: 50px;
    margin-bottom: 50px;

    @media screen and (max-width: 1000px){
        grid-template-columns: 1fr 1fr;
    };

    @media screen and (max-width: 800px){
        grid-template-columns: 1fr;
        padding: 0 20px;
    };
`;

export const ServicesCard = styled.div`
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    border-radius: 10px;
    padding: 30px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
`;

export const ServicesIcon = styled.img`
    height: 160px;
    width: 160px;
    margin-bottom: 10px;
`;

export const ServicesH1 = styled.h1`
    font-family: 'Cinzel';
    font-size: 2.5rem;
    color: #fff;
    margin-bottom: 64px;

    @media screen and (max-width: 480px){
        font-size: 2rem;
    };
`;

export const ServicesH2 = styled.h2`
    font-size: 1rem;
    margin-bottom: 10px;
`;

export const ServicesP = styled.p`
    font-size: 1rem;
    text-align: left;
    white-space: nowrap;
`;

export const ServicesColContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
`;

export const ServicesCol = styled.div`
  text-align: center;
  flex: 1; 
  white-space: nowrap;
`;

