import styled from "styled-components";
import { Link as LinkR } from 'react-router-dom';
import { MdKeyboardArrowRight, MdArrowForward } from 'react-icons/md';

export const HeroContainer = styled.div`
    opacity: 0.8;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
    object-fit: contain;
    background: url(${(props) => props.backgroundImage}) center center/cover no-repeat;
`;

export const HeroH1 = styled.h1`
    color: #fff;
    font-family: 'Cinzel';
    font-size: 48px;
    text-align: center;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);

    @media screen and (max-width: 768px) {
        font-size: 40px;   
    }
    @media screen and (max-width: 480px){
        font-size: 32px;  
    };
`;

export const HeroP = styled.p`
    font-family: 'Spinnaker';
    margin-top: 24px;
    color: #fff;
    font-size: 18px;
    text-align: center;
    max-width: 600px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);

    @media screen and (max-width: 768px) {
        font-size: 24px;   
    }
    @media screen and (max-width: 480px){
        font-size: 18px;  
    };
`;

export const HeroBtnWrapper = styled.div`
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ArrowForward = styled(MdArrowForward)`
    margin-left: 8px;
    font-size: 20px;
`;

export const ArrowRight = styled(MdKeyboardArrowRight)`
    margin-left: 8px;
    font-size: 20px;
`;

export const HeroBtn = styled(LinkR)`
    border-radius: 50px;
    background: #d4af37;
    white-space: nowrap;
    padding: 10px 22px;
    color: #0a0803;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: #fff;
        color: #0a0803;
    }
`;