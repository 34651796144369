import React from 'react';
import IconMap from '../../images/undraw_map_re_60yf.svg';
import IconOpen from '../../images/undraw_login_re_4vu2.svg';
import IconContact from '../../images/undraw_contact_us_re_4qqt.svg';
import {ServicesContainer, ServicesWrapper, ServicesCard, ServicesIcon, ServicesH2, ServicesP, ServicesColContainer, ServicesCol, ServicesH1} from './ServicesElements';

const Services = () => {
  return (
    <ServicesContainer id="contact">
        <ServicesWrapper>
            <ServicesCard>
                <ServicesIcon src={IconMap} />
                <ServicesH2>Address</ServicesH2>
                <ServicesP>43 Old London Rd</ServicesP>
                <ServicesP>Kingston upon Thames</ServicesP>
                <ServicesP>KT2 6ND</ServicesP>
                <ServicesP>UK</ServicesP>
            </ServicesCard>
            <ServicesCard>
            <ServicesIcon src={IconOpen} />
                <ServicesH2>Opening Hours</ServicesH2>
                <ServicesP>Appointment only after 18:00</ServicesP>
                <ServicesP>Mon-Tue: 10:00-20:00</ServicesP>
                <ServicesP>Wed: Closed</ServicesP>
                <ServicesP>Thur-Sat: 10:00-20:00</ServicesP>
                <ServicesP>Sun: 10:00-18:00</ServicesP>
            </ServicesCard>
            <ServicesCard>
            <ServicesIcon src={IconContact} />
                <ServicesH2>Contact Info</ServicesH2>
                <ServicesP>Tel.: 020 3105 4906</ServicesP>
                <ServicesP>Mobile: 07738 093372</ServicesP>
                <ServicesP>Email: goldenneedlehealthandbeauty@gmail.com</ServicesP>
            </ServicesCard>
        </ServicesWrapper>
    </ServicesContainer>
  )
}

export default Services
