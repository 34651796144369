import styled from 'styled-components';
import React, { useState, useEffect } from 'react';

export const MapContainer = styled.div`
    height: 700px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #0a0803;
    padding: 10px;
    min-height: 100%;

    @media screen and (max-width: 768px){
        height: 1100px;    
    };

    @media screen and (max-width: 480px){
        height: 1300px;   
    };
`;

export const GoogleMapEmbed = () => {
  return (
      <iframe
        title="Google Map"
        src="https://maps.app.goo.gl/8wyXZLYzBMPRCiDD7"
        width="100%"
        height="100%"
        allowFullScreen=""
        loading="lazy"
      ></iframe>
  );
};

export const MapsH1 = styled.h1`
    font-family: 'Cinzel';
    font-size: 2.5rem;
    color: #fff;
    margin-bottom: 64px;

    @media screen and (max-width: 480px){
        font-size: 2rem;
    };
`;



const PlaceDetails = () => {
  const [placeDetails, setPlaceDetails] = useState(null);
  const apiKey = 'AIzaSyC8behLIzEFqNdOqGTMVxcvUlGXmvvgE8A';
  const placeId = 'ChIJUe1S9ekLdkgRqRTwZUULNFA';

  useEffect(() => {
    // Fetch place details using the Google Places Details API
    fetch(
      `https://maps.googleapis.com/maps/api/place/details/json?fields=name,rating,formatted_phone_number,formatted_address,opening_hours,reviews&place_id=${placeId}&key=${apiKey}&language=en&region=uk`
    )
      .then((response) => response.json())
      .then((data) => {
        setPlaceDetails(data.result);
      })
      .catch((error) => {
        console.error('Error fetching place details:', error);
      });
  }, []);

  if (!placeDetails) {
    return <div>Loading...</div>;
  }

  // Render the place details
  return (
    <div>
      <h1>{placeDetails.name}</h1>
      <p>Rating: {placeDetails.rating}</p>
      <p>Phone Number: {placeDetails.formatted_phone_number}</p>
      {/* Add your map component here */}
    </div>
  );
};

export default PlaceDetails;

const apiKey = 'YOUR_API_KEY'; // Replace with your Google API key
const placeId = 'ChIJYUZN9ekLdkgRWiy78qH_pnI'; // Replace with your place_id
const fields = 'rating,formatted_phone_number,formatted_address,opening_hours,reviews';

const apiUrl = `https://maps.googleapis.com/maps/api/place/details/json?fields=${fields}&place_id=${placeId}&key=${apiKey}`;

fetch(apiUrl)
  .then((response) => response.json())
  .then((data) => {
    if (data.status === 'OK') {
      const businessDetails = data.result;
      console.log('Business Details:', businessDetails);
      // Handle the business details as needed
    } else {
      console.error('Error fetching business details:', data.status);
    }
  })
  .catch((error) => {
    console.error('Error fetching business details:', error);
  });
