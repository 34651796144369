export const homeObjAbout = {
    id: 'about',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    headline: 'GOLDEN NEEDLE HEALTH CARE & BEAUTY',
    topLine: 'A traditional Chinese medical clinic',
    description: 'Zhang is a highly experienced professional in acupuncture, massage, and traditional Chinese herbal medicine. She holds a bachelor\'s degree of Traditional Chinese Medicine (TCM) and has over 30 years of clinical practice. Her expertise includes acupuncture, acupressure, massage, cupping, reflexology, moxibustion, and herbal medicine. Zhang specializes in various health issues, including infertility, women\'s problems, depression, asthma, eczema, hayfever, and pain management. With a strong reputation for professionalism and dedication, she has earned the trust of her patients around the world.',
    buttonLabel: 'Get started',
    imgStart: false,
    img: require('../../images/shop_front.jpg'),
    alt: 'acupuncture', 
    dark: false,
    primary: true,
    darkText: true
}