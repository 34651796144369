import React from 'react';
import { SidebarContainer, Icon, CloseIcon, SidebarWrapper, SidebarMenu, SidebarLink, SideBtnWrap, SidebarRoute, SidebarLinkR } from './SidebarElements';

const Sidebar = ({ isOpen, toggle, activeSection }) => {
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    {activeSection === 'home'? (
                    <SidebarLink to="about" onClick={toggle}>
                        About Us
                    </SidebarLink>) : (
                    <SidebarLinkR to="/#aboutSection">
                        About Us
                    </SidebarLinkR>
                    )}
                    {activeSection === 'home'? (
                    <SidebarLink to="contact" onClick={toggle}>
                        Contact Us
                    </SidebarLink>) : (
                    <SidebarLinkR to="/#contactSection">
                        Contact Us
                    </SidebarLinkR>
                    )}
                    {activeSection === 'home'? (
                    <SidebarLink to="contact" onClick={toggle}>
                        Find Us
                    </SidebarLink>) : (
                    <SidebarLinkR to="/#contactSection">
                        Find Us
                    </SidebarLinkR>
                    )}
                    <SidebarLinkR to="/treatments" onClick={toggle}>
                        Treatments
                    </SidebarLinkR>
                    <SidebarLinkR to="https://giftup.app/place-order/88947639-f9a7-4f3d-985a-3dd5e4b46085" 
                    target='_blank' 
                    onClick={toggle}
                    >Gift Card</SidebarLinkR>
                </SidebarMenu>
                <SideBtnWrap>
                    <SidebarRoute 
                    to="https://widget.treatwell.co.uk/place/golden-needle-health-care-beauty/" 
                    onClick={toggle}
                    >Book Now</SidebarRoute>
                </SideBtnWrap>
            </SidebarWrapper>
        </SidebarContainer>
    );
};

export default Sidebar;
