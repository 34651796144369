import React from 'react';
import './App.css';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import Home from './pages';
import TreatmentsPage from './pages/treatments';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} exact />
        <Route path="/treatments" element={<TreatmentsPage />} exact />
      </Routes>
    </Router>
  );
};

export default App;
