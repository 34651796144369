import React, { useState } from 'react';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import { treatmentsObjAcupuncture, treatmentsObjAcupressure, treatmentsObjMassage, treatmentsObjCupping, treatmentsObjReflexology, treatmentsObjMoxibustion, treatmentsObjFacialAcupuncture, treatmentsObjFacialMassage, treatmentsObjHerbalFacial, treatmentsObjHerbalMedicine, treatmentsObjEarCandle } from '../components/Treatments/Data';
import Footer from '../components/Footer';
import TreatmentsInfoSection from '../components/Treatments';

const TreatmentsPage = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <Sidebar isOpen={isOpen} toggle={toggle} activeSection={''}/>
            <Navbar toggle={toggle} activeSection={''}/>
            <TreatmentsInfoSection {...treatmentsObjAcupuncture}/>
            <TreatmentsInfoSection {...treatmentsObjAcupressure}/>
            <TreatmentsInfoSection {...treatmentsObjMassage}/>
            <TreatmentsInfoSection {...treatmentsObjCupping}/>
            <TreatmentsInfoSection {...treatmentsObjReflexology}/>
            <TreatmentsInfoSection {...treatmentsObjMoxibustion}/>
            <TreatmentsInfoSection {...treatmentsObjFacialAcupuncture}/>
            <TreatmentsInfoSection {...treatmentsObjFacialMassage}/>
            <TreatmentsInfoSection {...treatmentsObjHerbalFacial}/>
            <TreatmentsInfoSection {...treatmentsObjHerbalMedicine}/>
            <TreatmentsInfoSection {...treatmentsObjEarCandle}/>
            <Footer />
        </>
    );
};

export default TreatmentsPage;