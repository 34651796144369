export const treatmentsObjAcupuncture = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: '',
    headline: 'Acupuncture',
    description: 'Insertion of thin needles into specific points to promote healing and balance in the body\'s energy.',
    imgStart: true,
    img: require('../../images/acupuncture.jpeg'),
    alt: 'workout', 
    darkText: true
}

export const treatmentsObjAcupressure = {
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: '',
    headline: 'Acupressure',
    description: 'Using manual pressure on specific points to relieve tension, promote relaxation, and improve overall well-being.',
    imgStart: false,
    img: require('../../images/acupressure.jpeg'),
    alt: 'workout', 
    darkText: false
}

export const treatmentsObjMassage = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: '',
    headline: 'Massage',
    description: 'Manipulation of soft tissues to alleviate muscle tension, reduce stress, and enhance physical and mental relaxation.',
    imgStart: true,
    img: require('../../images/massage.avif'),
    alt: 'workout', 
    darkText: true
}

export const treatmentsObjCupping = {
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: '',
    headline: 'Cupping',
    description: 'A therapy involving the use of suction cups to stimulate blood flow, relieve muscle tension, and promote overall health.',
    imgStart: false,
    img: require('../../images/cupping.jpeg'),
    alt: 'workout', 
    darkText: false
}

export const treatmentsObjReflexology = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: '',
    headline: 'Reflexology',
    description: 'A foot-focused therapy that applies pressure to specific reflex points to promote healing and relaxation throughout the body.',
    imgStart: true,
    img: require('../../images/reflexology.jpeg'),
    alt: 'workout', 
    darkText: true
}

export const treatmentsObjMoxibustion = {
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: '',
    headline: 'Moxibustion',
    description: 'The burning of dried herbs (usually mugwort) near the skin to stimulate specific acupuncture points and enhance healing.',
    imgStart: false,
    img: require('../../images/moxibustion.jpeg'),
    alt: 'workout', 
    darkText: false
}

export const treatmentsObjFacialAcupuncture = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: '',
    headline: 'Facial Acupuncture',
    description: 'A cosmetic treatment using fine needles on facial points to reduce wrinkles, improve skin tone, and promote a youthful appearance.',
    imgStart: true,
    img: require('../../images/facial_acupuncture.jpeg'),
    alt: 'workout', 
    darkText: true
}

export const treatmentsObjFacialMassage = {
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: '',
    headline: 'Facial Massage',
    description: 'A manual therapy involving gentle manipulation of facial muscles and tissues to improve circulation, relax facial muscles, and enhance skin tone and appearance.',
    imgStart: false,
    img: require('../../images/facial_massage.webp'),
    alt: 'workout', 
    darkText: false
}

export const treatmentsObjHerbalFacial = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: '',
    headline: 'Herbal Facial',
    description: 'A beauty treatment incorporating herbal ingredients to nourish and rejuvenate the skin.',
    imgStart: true,
    img: require('../../images/facial.jpeg'),
    alt: 'workout', 
    darkText: true
}

export const treatmentsObjHerbalMedicine = {
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: '',
    headline: 'Herbal Medicine',
    description: 'The use of plants and their extracts for medicinal purposes to treat various health conditions and promote well-being.',
    imgStart: false,
    img: require('../../images/herbal_medicine.jpeg'),
    alt: 'workout', 
    darkText: false
}

export const treatmentsObjEarCandle = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: '',
    headline: 'Ear Candle',
    description: 'An alternative therapy where a hollow candle is placed in the ear and lit, believed by some to remove earwax and promote ear and sinus health.',
    imgStart: true,
    img: require('../../images/ear_candle.jpeg'),
    alt: 'workout', 
    darkText: true
}