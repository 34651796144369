import React, { useState } from 'react';
import { HeroContainer, HeroH1, HeroP, HeroBtnWrapper, HeroBtn } from './HeroElements';
import img from '../../images/frontpage_background.jpeg';

const HeroSection = () => {
    const [hover, setHover] = useState(false);
    const onHover = () => {
        setHover(!hover);
    };

    return (
        <HeroContainer backgroundImage={img}>
            <HeroH1>YOUR HEALTH WE CARE</HeroH1>
            <HeroP>
                ALTERNATIVE MEDICINE CLINIC IN THE HEART OF KINGSTON
            </HeroP>
            <HeroBtnWrapper>
                <HeroBtn to="https://widget.treatwell.co.uk/place/golden-needle-health-care-beauty/"
                    target='_blank'
                    onMouseEnter={onHover}
                    onMouseLeave={onHover}>
                    Book Now
                </HeroBtn>
            </HeroBtnWrapper>
        </HeroContainer >
    );
};

export default HeroSection;
