import React from 'react';
import { animateScroll as scroll } from 'react-scroll';
import { FooterContainer, FooterWrap, FooterLinksContainer, FooterLinksWrapper, FooterLinkItems, FooterLinkTitle, SocialMedia, SocialMediaWrap, SocialLogo, WebsiteRights, FooterLinkParagraph } from './FooterElements';

const Footer = () => {

    const toggleHome = () => {
        scroll.scrollToTop();
    };

  return (
    <FooterContainer>
        <FooterWrap>
            <FooterLinksContainer>
                <FooterLinksWrapper>
                    <FooterLinkItems>
                        <FooterLinkTitle>Find Us</FooterLinkTitle>
                        <FooterLinkParagraph>43 Old London Road</FooterLinkParagraph>
                        <FooterLinkParagraph>Kingston upon Thames</FooterLinkParagraph>
                        <FooterLinkParagraph>KT2 6ND</FooterLinkParagraph>
                    </FooterLinkItems>
                    <FooterLinkItems>
                        <FooterLinkTitle>Visit Us</FooterLinkTitle>
                        <FooterLinkParagraph>Mon-Tue: 10:00-20:00</FooterLinkParagraph>
                        <FooterLinkParagraph>Wed: Closed</FooterLinkParagraph>
                        <FooterLinkParagraph>Thur-Sat: 10:00-20:00</FooterLinkParagraph>
                        <FooterLinkParagraph>Sun: 10:00-18:00</FooterLinkParagraph>
                        <FooterLinkParagraph>Appointment only after 18:00</FooterLinkParagraph>
                    </FooterLinkItems>
                    <FooterLinkItems>
                        <FooterLinkTitle>Contact Us</FooterLinkTitle>
                        <FooterLinkParagraph>Tel.: 020 3105 4906</FooterLinkParagraph>
                        <FooterLinkParagraph>Mobile: 07738 093372</FooterLinkParagraph>
                        <FooterLinkParagraph>Email: goldenneedlehealthandbeauty@gmail.com</FooterLinkParagraph>
                    </FooterLinkItems>
                    
                </FooterLinksWrapper>
            </FooterLinksContainer>
            <SocialMedia>
                <SocialMediaWrap>
                    <SocialLogo to='/' onClick={toggleHome}>
                        GOLDEN NEEDLE
                    </SocialLogo>
                    <WebsiteRights>Golden Needle © {new Date().getFullYear()} All rights reserved.</WebsiteRights>
                </SocialMediaWrap>
            </SocialMedia>
        </FooterWrap>
    </FooterContainer>
  )
};

export default Footer;
