import React from 'react';
import { GoogleMapEmbed, MapContainer, MapsH1 } from './MapElements';

const MapSection = () => {
  return (
    <MapContainer id="map">
        <MapsH1>FIND US</MapsH1>
        <GoogleMapEmbed />
    </ MapContainer>
  );
};

export default MapSection;
